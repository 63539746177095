import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import { Helmet } from 'react-helmet'

function BackPain() {
    return (
        <Layout>
            <Helmet>
                <title>Back pain - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Back Pain</h1>
            </Title>
            <section>
                <Text>
                    <Underline>
                        <h2>You're Not On Your Own</h2>
                    </Underline>
                    <p>Back pain affects up to 80% of us at some point in our lives. A recent study found that lower back pain caused more disability than any other condition, affecting 1 in 10 people and becoming more common with increasing age.</p>
                    <p>In the UK it is estimated that low back pain is responsible for 37% of all chronic pain in men and 44% in women and the total cost of low back pain to the UK economy is reckoned to be over £12 billion per year through leave of absence in the workplace caused by excessive aches and pains.</p>
                    <p>Sciatica is also a relatively common condition, with estimates suggesting that as many as 40% of people will experience it at some point in their lives. People who have sciatica usually have pain in the leg, and may also have low back pain. It is most commonly caused by a herniated intervertebral disc, but there are other causes of impingement of nerve in the lower back and buttocks.</p>
                    <p>Your spine is made of solid, bony blocks reinforced by strong ligaments and muscles. It is surprisingly difficult to damage your spine, but if strained, the surrounding muscles and ligaments can cause discomfort and pain.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Can an Osteopath Help?</h2>
                    </Underline>
                    <p>Often back pain resolves quickly by itself, but if it persists for more than a few days, an osteopath may be able to help. Osteopaths use a wide range of gentle hands-on techniques. Treatment varies between patients depending on your age, fitness and diagnosis, but often focuses on releasing tension, stretching muscles and mobilising joints – all of which may help to relieve your pain. Before you receive treatment your osteopath and you will jointly decide an appropriate and suitable treatment plan. Occasionally osteopathic techniques can result in an audible ‘click’, this is perfectly normal. Research evidence shows that these manipulations can have beneficial effects, especially in the back, helping you to return to normal activity.</p>
                    <p>About half of those who suffer an episode of back pain will have a recurrence of symptoms withina few years. Your osteopath can give you advice about what to do and how to keep active if your pain does return.</p>
                    <p>Stress can increase the amount of pain you feel by magnifying the effect of tension and muscle spasms. Your osteopath may talk to you about breathing and relaxation exercises to help relieve this tension.</p>
                    <p>Osteopaths are highly trained professionals who are skilled in diagnosing problems, including those which may require further investigation. Back pain is not normally caused by anything serious, though it is natural to worry about what may be causing your symptoms. Your osteopath will always complete a routine examination that checks for more serious diagnoses and will advise and discuss with you on any further action that might be required.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Can You Help Yourself?</h2>
                    </Underline>
                    <p>Here are some tips that will help you to care for your back:</p>
                    <ol>
                        <li>Early intervention can reduce the amount that you have to suffer and get you back to normal activities more quickly. It may be beneficial to seek advice sooner rather than later.</li>
                        <li>When lifting and carrying, always keep the item close to your body. Make sure to bend your knees and let your legs do the work. Try not to twist your back – turn with your feet.</li>
                        <li>Take regular exercise (aim for 30 minutes every day). People who are physically fit generally get less back pain, and recover faster if they do get it.</li>
                        <li>Pace yourself when undertaking any physical activity, especially when it is intensive or you are unaccustomed to it i.e. spring cleaning, or tidying the garden after the winter.</li>
                        <li>Adjust your car seats, use a rolled up towel to support your lower back and take regular breaks on long journeys.</li>
                        <li>Mattresses and sofas wear out over time and can cause back pain. If yours is over seven years old, it might be time to get a new one.</li>
                        <li>Keeping active can help with most back pain. Prolonged bed rest is usually not good for your back, and delays recovery. If you have any concerns about your back an osteopath may be able to help.</li>
                    </ol>
                    <h3>References:</h3>
                    <ol>
                        <li>Institute of Osteopathy Public Information Leaflet</li>
                        <li><a href="http://dx.doi.org/10.1016/S0140-6736(15)00195-6" target="_blank" rel="noopener noreferrer">Newton JN, et al. The Lancet. 2015;386(10010):2257–74</a></li>
                        <li><a href="https://www.gov.uk/government/publications/health-survey-for-england-2011" target="_blank" rel="noopener noreferrer">Health survey for England 2011</a></li>
                        <li><a href="http://www.ncbi.nlm.nih.gov/pubmed/10601677" target="_blank" rel="noopener noreferrer">The economic burden of back pain in the UK</a></li>
                    </ol>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default BackPain